import { ThemeContext } from "../utils/context"
import { useContext } from "react"

const Footer = () => {

    const {toggleTheme} = useContext(ThemeContext)

    return(
        <div className='magic' onClick={ () => toggleTheme()}>
            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.99 92.99"><path d="m83.72,9.27c2.07,2.07,4.81,3.5,7.86,3.93.66.08,1.2.59,1.29,1.28.11.82-.46,1.57-1.28,1.69-3.06.43-5.8,1.86-7.88,3.93-2.07,2.07-3.5,4.8-3.93,7.86-.08.66-.59,1.2-1.28,1.29-.82.11-1.57-.46-1.69-1.28-.43-3.06-1.86-5.8-3.93-7.88-2.07-2.07-4.8-3.5-7.86-3.93-.66-.08-1.2-.59-1.29-1.28-.11-.82.46-1.57,1.28-1.69,3.06-.43,5.8-1.86,7.88-3.93,2.07-2.07,3.5-4.81,3.93-7.86.08-.66.59-1.2,1.28-1.29.82-.11,1.57.46,1.69,1.28.43,3.06,1.86,5.8,3.93,7.88Zm.63,67.53c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5h-4.54v4.54c0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5v-4.54h-4.55c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h4.55v-4.55c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5v4.55h4.54Zm-63.61,0c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5h-4.55v4.54c0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5v-4.54h-4.54c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h4.54v-4.55c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5v4.55h4.55Zm0-63.61c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5h-4.55v4.55c0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5v-4.55h-4.54c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h4.54v-4.54c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5v4.54h4.55Zm41.81,17.26c7.69,7.69,17.81,12.95,29.14,14.57.66.08,1.2.59,1.29,1.28.11.82-.46,1.57-1.28,1.69-11.33,1.61-21.46,6.87-29.16,14.57-7.69,7.69-12.95,17.81-14.57,29.14-.08.66-.59,1.2-1.28,1.29-.82.11-1.57-.46-1.69-1.28-1.61-11.33-6.87-21.46-14.57-29.16-7.69-7.69-17.81-12.95-29.14-14.57-.66-.08-1.2-.59-1.29-1.28-.11-.82.46-1.57,1.28-1.69,11.33-1.61,21.46-6.87,29.16-14.57,7.69-7.69,12.95-17.81,14.57-29.14.08-.66.59-1.2,1.28-1.29.82-.11,1.57.46,1.69,1.28,1.61,11.33,6.87,21.46,14.57,29.16Z"/></svg>
        </div>
    )
}

export default Footer