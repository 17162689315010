
import { createGlobalStyle } from 'styled-components'
import { useContext } from "react"
import { ThemeContext } from "./context"

const themeVariant = [
    {
        id: 1,
        primary: 'pink',
        secondary: 'red'
    },
    {
        id: 2,
        primary: 'black',
        secondary: 'pink'
    },
    {
        id: 3,
        primary: 'white',
        secondary: 'red'
    },
]

const StyledGlobalStyle = createGlobalStyle`

    :root {
        --primary-color: ${({ theme }) => theme.primary};
        --secondary-color: ${({ theme }) => theme.secondary};
    }

    body {
        background-color: var(--primary-color);
        color: var(--secondary-color);
        transition: ease-in 0.45s;
    }
`

function GlobalStyle() {
    const { theme } = useContext(ThemeContext)

    const currentTheme = themeVariant.find((t) => t.id === theme) || themeVariant[0];

    return <StyledGlobalStyle theme={currentTheme} />
}

export default GlobalStyle